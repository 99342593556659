export default {
    name: 'transferTree',
    data () {
        return {}
    },
    props: {
        model: {
            type: Object,
            default: () => {}
        },
    },
    components: {},
    created () {},
    mounted () {},
    activated () {
    },
    deactivated() {},
    destroyed () {},
    watch: {},
    computed: {},
    filters: {
        getListNumFilters(list) {
            let num = 0
            let eachList = (listItem) => {
                if (listItem.userList && listItem.userList.length > 0) {
                    // num = num + listItem.workerList.length
                    listItem.userList.forEach(value => {
                        if (value.canCheck) {
                            num++
                        }
                    })
                }
                if (listItem.children && listItem.children.length > 0) {
                    listItem.children.forEach(val => {
                        eachList(val)
                    })
                }
            }
            eachList(list)
            return num
        }
    },
    methods: {
        handlerExpand(val) {
            this.$emit('handlerExpand', val)
        },
        toSelect(val) {
            this.$emit('toSelect', val)
        }
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/